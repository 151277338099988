// @refresh reload

import * as Sentry from "@sentry/solidstart";
import { solidRouterBrowserTracingIntegration } from "@sentry/solidstart/solidrouter";
import { StartClient, mount } from "@solidjs/start/client";

Sentry.init({
  dsn: "https://5446fd527953135d49ed497ee60e2823@o4507760217686016.ingest.us.sentry.io/4507878080053248",
  integrations: [
    solidRouterBrowserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.PROD ? "production" : "development",
});

// biome-ignore lint/style/noNonNullAssertion: generated by SolidStart, safe to ignore
mount(() => <StartClient />, document.getElementById("app")!);
